import React from "react";

import Layout from "../../../components/Layout/Layout";

const Article22022020 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              S&T AG: PROFIT (EBITDA) TO EXCEED 10% IN 2020 FOR THE FIRST TIME
            </h1>
            <p className="bullet">Increased EBITDA guidance for 2019</p>
            <p className="bullet">
              EBITDA to exceed 10% for the first time in 2020
            </p>
            <p className="bullet">
              Medium-term goal Agenda 2023: Increase of the EBITDA target from
              EUR 200 million to EUR 220 million
            </p>
            <p>
              S&T AG plans to continue its strong growth in the 2020 financial
              year and has announced its ambitious targets for the current year:
              Revenues are expected to reach at least EUR 1.250 billion and
              profitability around EUR 130 million EBITDA, which corresponds to
              an increase in the EBITDA guidance of around 25% compared to
              financial year 2019. This means that for the first time in S&T's
              history and already in 2020, the EBITDA margin target of 10%,
              formulated in the original Agenda 2023, will be exceeded.
            </p>
            <p>
              S&T AG's annual targets for 2020 are based on the successfully
              completed 2019 financial year. The company's preliminary figures
              indicate an EBITDA of around EUR 105 million, exceeding the
              originally communicated EBITDA target of EUR 100 million for the
              year with revenues of around EUR 1,145 billion.
            </p>
            <p>
              Due to the good order development, the company also confirms the
              Agenda 2023 targets of EUR 2,000 million revenues and an increased
              profitability from the original EUR 200 million to EUR 220 million
              EBITDA. Earnings per share (EPS) are also expected to grow
              disproportionately to more than EUR 1.75 by 2023, which
              corresponds to an increase of around 150% compared to 2018
              financial year's EUR 0.70.
            </p>
            <p>
              The profitability driver continues to be the high-margin business
              segment "IoT Solutions Europe", whereby an improvement is also
              expected for the IoT business in North America in the segment "IoT
              Solutions America" in 2020. The "PEC program" successfully
              launched in 2019 will be continued in the 2020 financial year and
              will also contribute to increasing profitability and effectiveness
              in the company. The PEC program has already enabled operating cash
              flow in the 2019 financial year to be significantly increased
              compared with 2018. The Annual Report 2019 of S&T AG will be
              published on March 26, 2020.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article22022020;
